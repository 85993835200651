section.fp-hero {
  background-position: center right;

 .hero-cta {
   @include phablet {
     padding-top: 165px;
     margin-bottom: 87px;
   }
   @include laptop {
     padding-top: 195px;
     margin-bottom: 117px;
   }
   @include wide {
     padding-top: 244px;
     margin-bottom: 275px;
   }
 }
}
.strapline {
  background-color: rgba(50,50,50,0.9);
  width: 100%;
  padding: 30px 0;
  z-index: 1;
  position: relative;
  @include tablet {
    padding: 55px 0;
  }
}
.ptext {
  color: $white;
  text-align: center;
  max-width: 375px;
  margin-left: auto;
  margin-right: auto;
  @include mobile {
      max-width: 1024px;
  }
  p {
    font-size: 20px;
    font-weight: 700px;
    line-height: normal;
    margin-bottom: 0;
    @include mobile {
      font-size: 22px;
    }
    @include tablet {
      font-size: 25px;
    }
  }
}
section {
  h1 {
    text-align: center;
    margin-top: 50px;
    font-weight: 500;
    font-size: 34px;
  }
  h3 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 30px;
    @include phablet {
      max-width: 190px;
    }
    @include wide {
    max-width: none;
    }
  }
  span.h1-subtext {
    font-weight: 500;
    font-size: 18px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
    text-align: center;
    max-width: 775px;
  }
  span.h3-subtext {
    line-height: 24px;
    display: block;
    font-size: 19px;
    margin-bottom: 30px;
  }
  .col-whychoose {
    overflow: hidden;
    position: relative;
    border: 2px solid $brand-primary;
    margin-bottom: 30px;
  }
  .img-cover {
    display: block;
    position: relative;
  }
  img.img-whychoose {
    width: 100%;
    border: 3px solid $brand-primary;
    border-bottom: 3px solid $white;
  }
  span.triangle-cover {
    width: 100%;
    height: 16%;
    overflow: hidden;
    display: block;

    position: absolute;
    bottom: -2px;
  }
  span.triangle {
    width: 0;
  	height: 0;
  	border-bottom: 100px solid $brand-primary;
  	border-right: 900px solid transparent;
    display: block;
    position: absolute;

    &:before {
      content: " ";
      width: 0;
    	height: 0;
    	border-bottom: 100px solid #fff;
    	border-right: 900px solid transparent;
      display: block;
      position: absolute;
      top: 5px;
    }
  }
  .col-whychoose-txt {
    z-index: 3;
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    .h3-subtext {
      max-height: 144px;
      margin-bottom: 0;
    }
    @include laptop {
      .h3-subtext {
        max-height: 168px;
        margin-bottom: 60px;
      }
    }
    @include desktop {
      .h3-subtext {
        margin-bottom: 30px;
      }
    }
    @include wide {
      padding-left: 25px;
      padding-right: 25px;
      .h3-subtext {
        max-height: 144px;
      }
    }
  }
}
section.whychoose {
  a.button {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 50px;
    display: block;
    text-align: center;
    max-width: 200px;
    @include mobile {
      max-width: 255px;
    }
  }
}
section.services-full {
  padding-bottom: 30px;
  background-image: url("../images/services-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  h1, .h1-subtext {
    color: $white;

  }
  span.h1-subtext {
    max-width: 877px;
  }
  h1 {
    padding-top: 50px;
  }

  a.service-anchor {
    font-weight: 700;
    text-decoration: underline;
    font-size: 19px;
    z-index: 9999;
    &:hover {
      color: $brand-primary;
    }
  }
  .col-serve {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    border: 2px solid $brand-primary;
    overflow: hidden;
    margin-bottom: 30px;
    @include tablet {
      flex-flow: row;
    }
    .img-cover {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      min-height: 250px;
      display: block;
      border: 2px solid $brand-primary;
      @include phablet {
        min-height: 211px;
      }
      @include tablet {
        min-height: 250px;
        width: 375px;
      }
      @include desktop {
        width: 354px;
      }
    }
    .col-serve-txt {
      margin-left: auto;
      position: relative;
      padding: 30px;
      background-color: transparent;
      @include desktop {
        padding: 30px 27px 30px 0;
      }
      h3, span.h3-subtext, a.service-anchor {
        z-index: 999999;
        position: relative;
        margin-top: 0;
      }
      h3 {
        max-width: none;
      }
      span.h3-subtext {
        margin-bottom: 15px;
        max-width: 500px;
        @include desktop {
          max-width: 350px;
        }
      }
      &:before {
        display: block;
        content: " ";
        background-color: #fff;
        position: absolute;
        height: 100%;
        width: 200%;
        top: -5px;
        left: -46px;
        border-top: 4px solid $brand-primary;
        z-index: 0;
        transform: rotate(6deg);
        @include tablet {
          top: 0;
          width: 90%;
          left: -46px;
          border-top: transparent;
          border-left: 4px solid $brand-primary;
          transform: skew(-10deg);
        }
      }
    }
  }
}
section.testimonials {
  padding-bottom: 30px;
  h1 {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
    @include mobile {
      max-width: none;
    }
  }
  span.h1-subtext {
    margin-bottom: 10px;
  }
  .slick-slide {
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 30px;
    padding-bottom: 30px;
    @include mobile {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
  .slick-single {
    .slick-flex {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    img.img-quote {
      display: none;
      @include laptop {
        display: block;
        height: auto;
        width: auto;
        margin-left: 0;
        margin-bottom: 30px;
      }
    }
    .quote {
      max-width: 100%;
      line-height: 22px;
      text-align: center;
      margin-right: auto;
      @include laptop {
        max-width: 390px;
        text-align: left;
      }
      @include desktop {
        max-width: 470px;
      }
      .quote-author {
        display: block;
        font-weight: 700;
        text-align: center;
        margin-top: 15px;
        @include laptop {
          text-align: right;
          // margin-top: 0;
        }
      }
    }
  }
  .slick-prev, .slick-next {
    height: 30px;
    &:before {
      color: $brand-primary;
      font-family: FontAwesome;
      content: "\f053";
      font-size: 30px;
    }
  }
  .slick-prev {
    @include desktop {
      left: -60px;
    }
  }
  .slick-next {
    @include desktop {
      right: -60px;
    }
    &:before {
      content: "\f054";
    }
  }
  .quote-profile-img, .quote-profile-img-mobile {
    display: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 290px;
    width: 33%;
    margin-left: auto;
    margin-right: 40px;
    position: relative;
    box-shadow: 4px 10px 31px 0px rgba(0,0,0,0.26);
    &:after {
      display: block;
      content: " ";
      width: 135%;
      height: 250px;
      border:solid 10px $brand-primary;
      position: absolute;
      top: 20px;
      left: -40px;
      z-index: -1;
    }
    @include laptop {
      display: block;
    }
    @include desktop {
      height: 322px;
      &:after {
        height: 288px;
      }
    }
    @include wide {
      margin-right: 70px;
    }
  }
  .quote-profile-img-mobile {
    display: block;
    height: 170px;
    width: 170px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
    &:after {
      width: 210px;
      height: 150px;
      border:solid 8px $brand-primary;
      position: absolute;
      top: 10px;
      left: -20px;
      z-index: -1;
    }
    @include laptop {
      display: none;
    }
  }

  .slick-dotted {
    padding-left: 0;
    padding-right: 0;
  }
  .slick-dots {
    left: 0;
    right: 0;
    button:before {
      font-size: 15px;
      color: $brand-primary;
    }
  }
}
section.testimonials-no-image {
  .slick-no-image {
    .quote {
      border:solid 8px $brand-primary;
      padding: 15px;
      @include laptop {
        padding-left: 80px;
      }

      max-width: none;
      position: relative;
      // width: 300px;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      img.img-quote {
        height: 87px;
        position: absolute;
        left: -20px;
        top: -20px;
      }
    }
  }
}
