section.footer-cta {
  background-image: url("../images/footer-1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    @include tablet {
      background-color: rgba(0,0,0,0);
    }
  }
  span.hero-txt-a {
    max-width: 750px;
    margin-bottom: 30px;
  }
  .hero-cta {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-bottom: 0;
    @include tablet {
      padding-top: 120px;
      padding-bottom: 120px;
    }
    @include desktop {
      padding-top: 220px;
      padding-bottom: 190px;
    }
  }
}
footer.footer {
  overflow: hidden;
  padding-top: 30px;
  .footer-logo-cover {
    display: flex;
    margin-bottom: 25px;
    span.redline {
      margin-top: 34px;
      width: calc(50% - 175px);
      display: none;
      height: 26px;
      max-width: none;
      background-image: url("../images/redline-3.jpg");
      @include mobile {
        display: block;
      }
      @include wide {
        background-image: url("../images/redline-2.jpg");
        height: 29px;
        margin-top: 36px;
        width: calc(50% - 204px);
      }
    }
    span.brand-cover {
      position: relative;
      display: block;
      width: 100%;
      img.logo {
        width: 100%;
      }
      @include mobile {
        width: 350px;
      }
      @include wide {
        width: 408px;
      }
      a.brand {
        display: block;
        font-family: $logo-font;
        font-weight: bold;
        font-size: 17px;
        line-height: 16px;
        display: block;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
  .col-schema {
    font-size: 19px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 5px;
    @include laptop {
      margin-bottom: 25px;
    }
    span.address {
      display: block;
      @include mobile {
        display: inline;
      }
    }
    a.facebook {
      margin-left: 10px;
    }
  }
  ul.nav {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    @include desktop {
      justify-content: space-between;
      flex-direction: row;
    }
    li.menu-item-31 {
      display: none;
    }
    li {
      font-size: 19px;
      font-weight: 500;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      @include desktop {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
    ul.sub-menu {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      justify-content: space-between;
      padding-left: 0;
      width: 100%;
      text-align: center;
      @include desktop {
        flex-direction: row;
      }
    }
    .menu-item-497 {
      width: 82%;
      margin-bottom: 0;
    }
    .menu-item-497>a {
      display: none;
    }
    @include desktop {
      .menu-item-497 {
        width: 34%;
      }
    }
  }
  ul.footer-nav {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    @include laptop {
      justify-content: space-between;
      flex-direction: row;
    }
    li.menu-item-31 {
      display: none;
    }
    li {
      font-size: 19px;
      font-weight: 500;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5px;
      @include laptop {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;
      }
    }
  }
  .copyright {
    font-size: 12px;
    background-color: #f9f9f9;
    padding: 10px 0;
    .col-copyright {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @include tablet {
        flex-direction: row;
        text-align: left;
      }
      .client-copy {
        @include tablet {
          margin-left: auto;
        }
      }
    }
  }
}
