section.interior-hero {
  height: 440px;
}

div.page-header, div.offwhite-bg {
  background-color: $offwhite;
}
div.page-header, div.form-header {
  padding-top: 170px;
  padding-bottom: 30px;
  @include mobile {
    padding-top: 153px;
  }
  h1 {
    margin-bottom: 0;
    line-height: 28px;
  }
}
.products-container {
  .product-row {
    margin-bottom: 30px;
  }
  a.service-anchor {
    display: block;
    font-weight: 700;
    text-decoration: underline;
    font-size: 19px;
    z-index: 9999;
    i.fa {
      font-size: 16px;
      margin-left: 5px;
    }
    &:hover {
      color: $brand-primary;
    }
  }
  a.service-anchor-cut {
    max-width: 190px;
  }
  .col-product {
    display: flex;
    flex-flow: column;
    background-color: #fff;
    border: 2px solid $brand-primary;
    overflow: hidden;
    margin-bottom: 30px;
    @include tablet {
      flex-flow: row;
    }
    .img-cover {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      min-height: 250px;
      display: block;
      border: 2px solid $brand-primary;
      @include phablet {
        min-height: 211px;
      }
      @include tablet {
        width: 45%;
      }
    }

    .col-product-txt {
      margin-left: auto;
      position: relative;
      padding: 30px;
      background-color: transparent;
      @include tablet {
        width: 55%;
      }
      @include desktop {
        padding: 30px 27px 30px 0;
        min-height: 320px;
      }
      h3, span.h3-subtext, a.service-anchor {
        z-index: 999999;
        position: relative;
        margin-top: 0;
      }
      h3 {
        max-width: none;
      }
      span.h3-subtext {
        display: block;
        margin-bottom: 15px;

      }
      &:before {
        display: block;
        content: " ";
        background-color: #fff;
        position: absolute;
        height: 100%;
        width: 200%;
        top: -5px;
        left: -46px;
        border-top: 4px solid $brand-primary;
        z-index: 0;
        -webkit-transform: rotate(6deg);
        -moz-transform: rotate(6deg);
        -o-transform: rotate(6deg);
        @include tablet {
          top: 0;
          width: 90%;
          left: -46px;
          border-top: transparent;
          border-left: 4px solid $brand-primary;
          -webkit-transform: skew(-10deg);
          -moz-transform: skew(-10deg);
          -o-transform: skew(-10deg);
        }
      }
    }
  }
  .col-product-sidebar, .col-product-sidebar .img-cover, .col-product-sidebar .col-product-txt::before {
    border-color: grey;

  }
  // .col-product-sidebar {
  //   .img-cover {
  //     min-height: 100px;
  //     width: 45%;
  //     background-image: url('../images/examples/door-opener-legacy-chain.jpg');
  //
  //   }
  //   .col-product-txt {
  //     min-height: 0px;
  //     padding: 0;
  //     width: 55%;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     &:before {
  //       left: -20px;
  //       width: 30%;
  //     }
  //   }
  //
  //
  // }
}


.cardstyle, section.widget  {
  background-color: $white;
  padding: 15px;
  border: 2px solid $brand-secondary;
  margin-bottom: 30px;
  a.button {
    background-color: $brand-secondary;
    color: #fff;
    &:hover {
      background-color: lighten($brand-secondary, 10%);
    }
  }
}
.cardstyle-grey, section.widget {
  border: 2px solid grey;
}
body.page-template-template-products-new {
  .cardstyle-grey {
    p {
      margin-bottom: 0;
    }

  }
}

.cardstyle-grey {
  img.alignleft {
    min-width: 100%;
    @include phablet {
      margin-right: 15px;
      min-width: inherit;
    }
  }

  img.full-width-tablet {
    min-width: 100%;
    @include tablet {
      min-width: 0;
    }
  }
  iframe {
    width: 100%;
    border: transparent;
  }
  iframe.margin-bottom {
    margin-bottom: 15px;
  }
  .about-last-div {
    // height: 540px;
    img {
      width: 100%;
      display: block;
    }
    @include tablet {
      height: 240px;
      img {
        width: 233px;
      }
    }
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  .FAQ {
    border-bottom: solid 2px $brand-primary;
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-of-type {
      border-bottom: transparent;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  .openers-title {
    min-height: 100px;
    height: auto;
    img.alignleft {
      float: left;
    }
  }
  .openers, .openers-title {
    margin-bottom: 30px;
  }
  a {
    color: $brand-secondary;
  }
  a.brochure {
    &:after {
      content: " \f1c1";
      font-family: FontAwesome;
      font-size: 14px;
    }
  }
  .video-row {
    .col-lg-6 {
      display: flex;
      flex-direction: column;
      p {
        margin-top: auto;
      }
    }
    h2 {
      font-size: 18px;
      margin-top: 15px;
    }
  }
  .cardstyle-row {
    margin-bottom: 30px;

    @include phablet {
      margin-left: 0;
      margin-right: 0;
    }
    .col-sm-6 {
      display: flex;
      justify-content: center;
      align-items:flex-start;
      img {
        min-width: 100%;
        margin-bottom: 15px;
        @include phablet {
          margin-bottom: none;
        }
      }
    }
    .col-12 {
      // margin-top: 15px;
      margin-bottom: 15px;
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .before-and-after {
    margin-bottom: -15px;
    .col-sm-6 {
      img {
        margin-bottom: 15px;
        @include phablet {
          margin-bottom: 30px;
        }
      }
    }
  }
}
.cardstyle-red {
  border: 2px solid $brand-primary;
}

.cardstyle-offwhite {
    border: transparent;
    background-color: #ececec;
    padding: 15px;
}

.locations-container, .blog-container {
  padding-bottom: 30px;
}
.forms-container {
  padding-bottom: 30px;
  img.services-img {
    width: 100%;
    margin-bottom: 5px;
  }
  // .cardstyle-offwhite {
  //   padding-top: 1px;
  //   padding-bottom: 1px;
  // }
  .map-panel-forms {
    .map_canvas {
      width: 100%;
      height: 301px;
    }
    margin-bottom: 30px;
    @include desktop {
      margin-bottom: 0;
    }
  }
}
section.widget {
  h3 {
    margin-top: 0;
    margin-bottom: 15px;
  }
  ul {
    margin-bottom: 0;
  }
}
.entry-summary {
  p {
    margin-bottom: 15px;
    a {
      display: none;
    }
  }
}
time {
  display: block;
  margin-bottom: 15px;
  color: #3c3c3c;
}
.entry-content-primary {
  p {
    font-size: 18px;
    letter-spacing: 0.02em;
    line-height: 30px;
  }
}

.breadcrumbs {
  margin-bottom: 30px;
  span {
    color: #3c3c3c;
  }
}
.default-page-container {
  h2 {
    margin-bottom: 1rem;
  }
}
.products-fairfield {
  .col-product {
    flex-direction: column;
    .img-cover, .col-product-txt {
      width: 100%;
    }
    // .img-cover {
    //   @include desktop {
    //     min-height: 130px;
    //   }
    //   @include wide {
    //     min-height: 150px;
    //   }
    // }
    .col-product-txt {
      min-height: 0;
      padding: 5px 10px 10px 10px;
      @include phablet {
        height: 76px;
      }
      &:before {
        left: 0;
        right: 0;
        top: -30px;
        border-left: transparent;
        border-top: 4px solid $brand-primary;
        background-color: #fff;
        transform: skew(0deg,5deg);
        width: 100%;
        height: 300px;
        @include phablet {
          top: -15px;
        }
      }
    }
  }
}

.primary_pagination {
  font-weight: 400;
  a.page-numbers, .current {
    padding: 4px 13px;
    border: 1.25px solid $brand-secondary;
    display: inline-block;
    margin: 0 3px 0 0;
  }
  a.page-numbers {
    color: $brand-secondary;
    &:link, :visited {
      color: $brand-secondary;
    }
    &:hover, :active {
      background-color: $brand-secondary;
      color: #fff;
    }
  }
  .current {
    background-color: $brand-secondary;
    color: #fff;
  }
  .hide-text {
    display: none;
    @include laptop {
      display: inline-block;
    }
  }
  .title-line {
    margin-top: 60px;
    margin-bottom: 30px;
  }
  @include tablet {
    .title-line {
      display: none;
    }
  }
}
.error404-inner {
  background-color: #ececec;
  padding-top: 150px;
  padding-bottom: 30px;
  .col-md-8 {
    text-align: center;
  }
}
