a.button, input.gform_button {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-transform: uppercase;
  background-color: #ee3229;
  padding: 10px 10px 12px 10px;
  // width: 100%;
  text-align: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    background-color: darken($brand-primary, 10%);
  }
  @include mobile {
    padding: 13px 30px 17px 30px;
    width: auto;
    text-align: left;
    font-size: 23px;
    line-height: 23px;
  }
}
input.gform_button {
  border-radius: 0;
  border: transparent;
}
