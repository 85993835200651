// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.gform_wrapper {
  ul.gform_fields li.gfield {
    padding-right: 0!important;
  }
  h3.gform_title {
    margin-top: 0;
    margin-bottom: 15px;
    // color: #fff;
  }
  ul.gform_fields {
    padding-left: 0;
    list-style-type: none;
    label.gfield_label {
      font-weight: 700;
      margin-bottom: 5px;
      // color: #fff;
    }
    .ginput_container_textarea {
      textarea.large {
            height: 200px;
      }
    }
    input.large, textarea.large {
      width: 100%;
      margin-bottom: 10px;
      border: transparent;
      background-color: #fff;
    }
  }
}
//Remove Captcha label
.g-recaptcha label {
  @extend .sr-only;
}



// #field_3_5, #field_1_5, #field_4_5 {
//   margin-top: 10px;
//   label {
//     display: none;
//   }
// }
